import React, { useRef } from 'react';
import './AnimationWrapper.scss';

import { useInView } from 'framer-motion';

interface IAnimationWrapper {
  children: JSX.Element;
  delay?: string;
  alignLeft?: boolean
}

const AnimationWrapper: React.FC<IAnimationWrapper> = ({ children, delay, alignLeft }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 100px -50px 0px', once: true });

  return (
    <section className='animation_wrapper' style={{ justifyContent: alignLeft ? 'start' : 'center' }} ref={ref}>
      <span
        style={{
          transform: isInView ? 'none' : 'translateY(100px)',
          opacity: isInView ? 1 : 0,
          transition: `all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay || 0.7}s`
        }}
      >
        {children}
      </span>
    </section>
  );
};

export default AnimationWrapper;
