import React, { useRef } from 'react';
import './CheckMark.scss';
import { useInView } from 'framer-motion';

const CheckMark: React.FC = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 100px -50px 0px', once: false });

  return (
    <div ref={ref} className="success_wrapper">
      <div
        style={{
          transform: isInView ? 'none' : 'scale(0.4)',
          opacity: isInView ? 1 : 0.5,
          transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)'
        }}
        className="check_wrapper"
      >
        <div className="check" />
      </div>
      <span
        className="success_text"
        style={{
          transform: isInView ? 'none' : 'translateY(-25px)',
          opacity: isInView ? 1 : 0,
          transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s'
        }}
      >
        Спасибо, Ваша информация получена! <br /> Ждём вас на торжестве, <br />
        Ваши Владислав и Анна
      </span>
    </div>
  );
};

export default CheckMark;
