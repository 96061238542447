import React, { useRef } from 'react';
import { useInView } from 'framer-motion';

import greenHeart from './assets/green_heart.png';
import welcomePhoto from './assets/welcome_photo.jpg';
import gift1 from './assets/gift1.svg';
import wine from './assets/wine.svg';
import ruble from './assets/ruble.svg'
import cross from './assets/cross.svg';
import check from './assets/check.svg';
import dress1 from './assets/dresscode/dress1.jpg';
import dress2 from './assets/dresscode/dress2.jpg';
import dress4 from './assets/dresscode/dress4.jpg';
import dress6 from './assets/dresscode/dress6.jpg';
import dress5 from './assets/dresscode/dress5.jpg';
import dress7 from './assets/dresscode/dress7.jpg';
import './App.scss';
import Countdown from './components/Countdown/Countdown';
import Form from './components/Form/Form';
import AnimationWrapper from './components/AnimationWrapper/AnimationWrapper';

const App: React.FC = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 100px -50px 0px' });

  return (
    <div className="wrapper">
      <div id='main'>
        <div className='main_text'>
          <AnimationWrapper delay={'0.4'}>
         <span className="main_names">Vladislav&Anna</span>
          </AnimationWrapper>
          <AnimationWrapper delay={'0.7'}>
         <span className="main_date">10.09.2024</span>
          </AnimationWrapper>
        </div>
      </div>
      <div className='card_wrapper'>
          <div className='card'>
            <AnimationWrapper>
            <p className="card_title">Дорогие Близкие и Друзья</p>
            </AnimationWrapper>
            <AnimationWrapper>
            <p className="card_description">Мы хотим пригласить вас
              на теплый и радостный праздник - нашу свадьбу!
              <br />
              Мы будем счастливы разделить самые важные моменты этого дня вместе с вами!
            </p>
            </AnimationWrapper>
            <div>
              <AnimationWrapper>
                <p className="countdown_text">До нашего важного события осталось</p>
              </AnimationWrapper>
              <Countdown />
            </div>
            <img className='welcome_photo' src={welcomePhoto} />
            <img className='bottom_heart' id='h1' src={greenHeart} />
            <img className='bottom_heart' id='h2' src={greenHeart} />
            <img className='bottom_heart' id='h3' src={greenHeart} />
            <img className='bottom_heart' id='h4' src={greenHeart} />
          </div>
      </div>
      <div className='card_wrapper'>
        <div className='card'>
          <AnimationWrapper delay={'0.4'}>
          <p className="card_title">Программа</p>
          </AnimationWrapper>
          <div className="schedule">
            <div className="schedule_item">
              <AnimationWrapper>
                <span className="schedule_time">11:40</span>
              </AnimationWrapper>
              <span className="schedule_minus">—</span>
              <div className="schedule_text_wrapper">
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_title">Регистрация в ЗАГС</p>
                </AnimationWrapper>
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_desc">
                    По адресу: ул. Фурштатская 52, Дворец бракосочетания #2
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="schedule_item">
              <AnimationWrapper>
                <span className="schedule_time">14:00</span>
              </AnimationWrapper>
              <span className="schedule_minus">—</span>
              <div className="schedule_text_wrapper">
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_title">Фуршет</p>
                </AnimationWrapper>
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_desc">
                    По адресу: Набережная реки Фонтанки 59, Event Roof. Обязательно угощайтесь шампанским. Пузырьки отлично создают праздничное настроение.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="schedule_item">
              <AnimationWrapper>
                <span className="schedule_time">15:00</span>
              </AnimationWrapper>
              <span className="schedule_minus">—</span>
              <div className="schedule_text_wrapper">
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_title">Начало праздничного вечера</p>
                </AnimationWrapper>
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_desc">
                    Дегустировать блюда, участвовать в интерактивах, шутить, петь и танцевать - разрешается.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="schedule_item">
              <AnimationWrapper>
                <span className="schedule_time">19:00</span>
              </AnimationWrapper>
              <span className="schedule_minus">—</span>
              <div className="schedule_text_wrapper">
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_title">Вкусный торт</p>
                </AnimationWrapper>
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_desc">
                    Попробовать должен каждый.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
            <div className="schedule_item">
              <AnimationWrapper>
                <span className="schedule_time">19:10</span>
              </AnimationWrapper>
              <span className="schedule_minus">—</span>
              <div className="schedule_text_wrapper">
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_title">Танцевальная часть</p>
                </AnimationWrapper>
                <AnimationWrapper alignLeft>
                  <p className="schedule_text_desc">
                    Пляшите до упаду, не жалея ни ног, ни каблуков.
                  </p>
                </AnimationWrapper>
              </div>
            </div>
          </div>
          <img className='bottom_heart' id='h1' src={greenHeart}/>
          <img className='bottom_heart' id='h2' src={greenHeart}/>
          <img className='bottom_heart' id='h3' src={greenHeart}/>
          <img className='bottom_heart' id='h4' src={greenHeart}/>
        </div>
      </div>
      <div className='card_wrapper'>
        <div className='card' id='gifts'>
          <AnimationWrapper delay={'0.4'}>
            <p className="card_title">Подарки</p>
          </AnimationWrapper>
          <AnimationWrapper>
            <p className="card_description">Приносите с собой веселье и радость в душе, а подарки – в конверте! Пожалуйста, воздержитесь от покупки живых цветов, заменив их
              бутылочкой любого алкогольного напитка на ваш вкус для нашей домашней коллекции.
          </p>
          </AnimationWrapper>
          <div className='gifts_wrapper'>
            <AnimationWrapper delay={'0.4'}>
            <div className='gift'>
              <img className='gift_mark' src={cross} />
              <img className='gift_icon' src={gift1} />
            </div>
            </AnimationWrapper>
            <AnimationWrapper delay={'0.6'}>
            <div className='gift'>
              <img className='gift_mark' src={check} />
              <img className='gift_icon' src={wine} />
            </div>
            </AnimationWrapper>
            <AnimationWrapper delay='0.8'>
            <div className='gift'>
              <img className='gift_mark' src={check} />
              <img className='gift_icon' src={ruble} />
            </div>
            </AnimationWrapper>
          </div>
          <img className='bottom_heart' id='h1' src={greenHeart} />
          <img className='bottom_heart' id='h2' src={greenHeart} />
          <img className='bottom_heart' id='h3' src={greenHeart} />
          <img className='bottom_heart' id='h4' src={greenHeart} />
        </div>
      </div>
      <div className='card_wrapper'>
        <div className='card'>
          <AnimationWrapper delay={'0.4'}>
          <p className="card_title">Дресс-код</p>
          </AnimationWrapper>
          <AnimationWrapper>
            <p className="card_description">Чтобы воспоминания о свадьбе были положительными, а фотографии уникальными, мы просим Вас поддержать цветовую палитру нашей свадьбы и использовать <strong>пастельные оттенки</strong>.
          </p>
          </AnimationWrapper>
          <div className='dress_wrapper'>
            <div className='dress_photo'>
              <img id='photo1' src={dress6}/>
              {/*<span className='dress_text'>Белый</span>*/}
            </div>
            <div className='dress_photo' id='reversed'>
              <img id='photo2' src={dress4}/>
              {/*<span className='dress_text'>Бежевый</span>*/}
            </div>
            <div className='dress_photo'>
              <img id='photo1' src={dress2}/>
              {/*<span className='dress_text'>Белый</span>*/}
            </div>
          </div>
          {/*<div className='colors_wrapper'>*/}
          {/*  <AnimationWrapper>*/}
          {/*  <div className="color_block" id='white'/>*/}
          {/*  </AnimationWrapper>*/}
          {/*  <AnimationWrapper>*/}
          {/*  <div className="color_block" id='beige'/>*/}
          {/*  </AnimationWrapper>*/}
          {/*</div>*/}
          <AnimationWrapper>
            <span className="color_desc">Мужчины: костюм или рубашка и брюки</span>
          </AnimationWrapper>
          <img className='bottom_heart' id='h1' src={greenHeart} />
          <img className='bottom_heart' id='h2' src={greenHeart} />
          <img className='bottom_heart' id='h3' src={greenHeart} />
          <img className='bottom_heart' id='h4' src={greenHeart} />
        </div>
      </div>
      <div className='card_wrapper'>
        <div className='card'>
        <Form />
          <img className='bottom_heart' id='h1' src={greenHeart} />
          <img className='bottom_heart' id='h2' src={greenHeart} />
          <img className='bottom_heart' id='h3' src={greenHeart} />
          <img className='bottom_heart' id='h4' src={greenHeart} />
        </div>
      </div>
    </div>
  );
};

export default App;
