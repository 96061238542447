import React, { useState } from 'react';
import { intervalToDuration } from 'date-fns';

import './Countdown.css';
import useInterval from '../../hooks/ui';

const Countdown: React.FC = () => {
  const [duration, setDuration] = useState<Duration | null>(null);

  useInterval(() => {
    const updatedDuration = intervalToDuration({
      start: new Date(),
      end: new Date(2024, 8, 10, 11, 40, 0)
    });
    setDuration(updatedDuration);
  }, 1000);

  if (!duration) return null;

  return (
    <div className="countdown_wrapper">
      <div className="countdown_item_wrapper">
        <span className="countdown_number">{duration.months}</span>
        <span className="countdown_text">Месяц</span>
      </div>
      <div className="countdown_item_wrapper">
        <span className="countdown_number">{duration.days}</span>
        <span className="countdown_text">Дней</span>
      </div>
      <div className="countdown_item_wrapper">
        <span className="countdown_number">{duration.hours}</span>
        <span className="countdown_text">Часов</span>
      </div>
      <div className="countdown_item_wrapper">
        <span className="countdown_number">{duration.minutes}</span>
        <span className="countdown_text">Минут</span>
      </div>
      <div className="countdown_item_wrapper">
        <span className="countdown_number">{duration.seconds}</span>
        <span className="countdown_text">Секунд</span>
      </div>
    </div>
  );
};

export default Countdown;
